import { Fragment, useMemo, useState } from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { Skeleton } from "@material-ui/lab"

import { EmptyState } from "src/components/EmptyState"
import { NoEvents } from "src/components/EmptyState/NoEvents"
import {
  SmokeFeedbackDialog,
  TEventFeedbackData,
} from "src/components/EventLog/SmokeFeedbackDialog"
import {
  TEventContent,
  useGetTranslatedEvent,
} from "src/components/EventLog/useGetTranslatedEvent"
import {
  EventType,
  IEvent,
  IEventTextParam,
} from "src/data/events/types/eventTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import SearchIcon from "src/ui/icons/search-no-results.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { LocalizedDate } from "src/ui/LocalizedDate/LocalizedDate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { unique } from "src/utils/genericUtil"

type THomeMetaData = {
  name: IHome["name"]
  id: string
  timezone: IHome["timezone"] | undefined
  address: IHome["address"]
}

export function EventLog({
  events,
  loading,
  hideHomeColumn,
  isFiltered,
}: {
  events: IEvent[]
  loading: boolean
  hideHomeColumn?: boolean
  isFiltered?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()

  const homeIds = useMemo(() => {
    return unique(events.map((e) => e.home_id))
  }, [events])

  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      ids: homeIds,
      limit: homeIds.length,
    },
  })
  const homes = fetchHomes.data?.homes

  const tEvent = useGetTranslatedEvent()

  const headers = [
    <div key={"event"}>{t(langKeys.event)}</div>,
    <div key={"sensor"}>{t(langKeys.sensor)}</div>,
    <div key={"home"}>{t(langKeys.home)}</div>,
    <div key={"time"}>{t(langKeys.time)}</div>,
  ].filter((h) =>
    hideHomeColumn ? !(h.props?.children === t(langKeys.home)) : true
  )

  const data: (IEvent & { home: THomeMetaData })[] = useMemo(() => {
    return events.map((e) => {
      const home = homes?.find((h) => h.home_id === e.home_id)

      return {
        ...e,
        home: {
          name: home?.name,
          id: e.home_id,
          timezone: home?.timezone,
          address: home?.address,
        },
      }
    })
  }, [events, homes])

  const rows = data.map((dataEntry) => {
    const timezone = dataEntry.home?.timezone
    const eventContent = tEvent(dataEntry, timezone)
    const { text_params, home, created_at, device_id } = dataEntry

    return (
      <Fragment key={dataEntry.event_id}>
        <div>
          <EventContent
            eventContent={eventContent}
            eventType={dataEntry.type}
            feedbackData={{
              eventId: dataEntry.event_id,
              deviceId: dataEntry.device_id,
              area: "", // Leaving blank for now; there's currently no way to get the room that triggered an event
              address: home.name,
              date: created_at,
              timezone: home.timezone,
            }}
          />
        </div>
        <div>{renderSensor(text_params, device_id)}</div>
        {!hideHomeColumn && <div>{renderHome(home)}</div>}
        <LocalizedDate date={created_at} timezone={timezone} />
      </Fragment>
    )
  })

  return (
    <>
      <TableBox>
        <GridTable
          rows={loading ? createSkeletonRows() : rows}
          header={headers}
          templateColumns={hideHomeColumn ? "3fr 1fr 1fr" : "3fr 1fr 1fr 1fr"}
          useFallbackResponsiveMode
        />
      </TableBox>
      {!data.length && !loading && (
        <EmptyStateBox>
          {isFiltered ? (
            <EmptyState
              icon={
                <SearchIconBox>
                  <SearchIcon width={60} height={60} />
                </SearchIconBox>
              }
              title={t(langKeys.search_nothing_found)}
            />
          ) : (
            <NoEvents />
          )}
        </EmptyStateBox>
      )}
    </>
  )
}

function createSkeletonRows() {
  const createSkeletonRow = () => (
    <Fragment key={Math.random()}>
      {/* //event */}
      <div>
        <Skeleton
          variant="text"
          width={`${Math.floor(Math.random() * 40) + 60}%`}
        />
      </div>
      {/* //sensor */}
      <div>
        <Skeleton
          variant="text"
          width={`${Math.floor(Math.random() * 40) + 60}%`}
        />
      </div>
      {/* //home  */}
      <div>
        <Skeleton
          variant="text"
          width={`${Math.floor(Math.random() * 40) + 60}%`}
        />
      </div>
      {/* //time */}
      <div>
        <Skeleton variant="text" />
      </div>
    </Fragment>
  )

  return [createSkeletonRow(), createSkeletonRow(), createSkeletonRow()]
}

function EventContent({
  eventContent,
  eventType,
  feedbackData,
}: {
  eventContent: TEventContent
  eventType: EventType
  feedbackData: TEventFeedbackData
}) {
  const showSmokeFeedbackButton =
    eventType === EventType.SMOKING_DETECTION_SMOKING_DETECTED

  const [showSmokeFeedbackForm, setShowSmokeFeedbackForm] = useState<boolean>()
  const { t, langKeys } = useTranslate()

  return (
    <EventBox>
      {eventContent.icon}

      <div>
        <GeneratedEventContent>
          <MText variant="subtitle">{eventContent.title}</MText>
        </GeneratedEventContent>
        {eventContent.body && (
          <MText variant="bodyS" color="secondary">
            <GeneratedEventContent>
              <ReactMarkdown>{eventContent.body}</ReactMarkdown>
            </GeneratedEventContent>
          </MText>
        )}

        {showSmokeFeedbackButton && (
          <TextButton onClick={() => setShowSmokeFeedbackForm(true)}>
            {t(langKeys.log_feedback_event_cigarette_detection)}
          </TextButton>
        )}

        <SmokeFeedbackDialog
          open={!!showSmokeFeedbackForm}
          onClose={() => setShowSmokeFeedbackForm(false)}
          feedbackData={feedbackData}
        />
      </div>
    </EventBox>
  )
}

function renderSensor(textParams: IEventTextParam[], deviceId?: string) {
  if (!textParams || !deviceId) return "-"
  const device = textParams.find((o) => o.key === "device_name")
  if (!device) return "-"
  return (
    <InternalLink to={Routes.SensorsWithDeviceId.location(deviceId)}>
      {device.value}
    </InternalLink>
  )
}

function renderHome(home: Home) {
  if (!home.id) return "-"
  return !!home.name ? (
    <InternalLink to={`/homes/${home.id}`}>{home.name}</InternalLink>
  ) : (
    "-"
  )
}

interface Home {
  name?: string
  id: string
  timezone?: string
}

const TableBox = styled.div`
  width: 100%;
  overflow-x: auto;
`

const GeneratedEventContent = styled.div`
  > p:first-child {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }
`

const EventBox = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  gap: ${spacing.L};
`

const SearchIconBox = styled.div`
  background-color: #f6f9fc;
  border-radius: 50%;
  padding: ${spacing.L};
`

const EmptyStateBox = styled.div`
  margin-top: ${spacing.XL6};
`
