import { useState } from "react"
import styled from "styled-components"

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core"

import { usePostSmokeFeedback } from "src/components/EventLog/smokeFeedbackQueries"
import { useFetchDevice } from "src/data/devices/queries/deviceQueries"
import { IEvent } from "src/data/events/types/eventTypes"
import { IHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useStorage } from "src/data/storage/useStorage"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import CigaretteIcon from "src/ui/icons/cigarette-smoking.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"
import { localStorageFactory } from "src/utils/storageUtil"
import { Optional } from "src/utils/tsUtil"

const RESPONSES = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "Don't know", value: "unknown" },
] as const
type TResponseType = (typeof RESPONSES)[number]["value"]

export function SmokeFeedbackDialog({
  open,
  onClose,
  feedbackData,
}: TMDialogProps & { feedbackData: TEventFeedbackData }) {
  const { _t, langKeys } = useTranslate()
  const postSmokeFeedback = usePostSmokeFeedback()

  const [response, setResponse] = useState<TResponseType>("yes")

  const {
    clear: clearComment,
    set: setComment,
    data: comment,
  } = useStoredCigaretteFeedback()

  function resetForm() {
    clearComment()
    setResponse("yes")
  }

  function handleConfirm() {
    postSmokeFeedback.mutate(
      {
        question_id: "smoking_detected_correctly",
        feedback_item_id: feedbackData.eventId,
        answer: { comment: comment ?? "", response },
        event_type: "smoking_detection_smoking_detected",
      },
      {
        onSuccess(data, variables, context) {
          resetForm()
          onClose()
        },
      }
    )
  }

  return (
    <MDialog
      open={open}
      title={_t(langKeys.send_feedback)}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmLabel={_t(langKeys.send_feedback)}
      hideClose
      loading={postSmokeFeedback.isLoading}
      error={
        postSmokeFeedback.isError && _t(langKeys.failed_something_went_wrong)
      }
    >
      <DialogContent>
        <SmokeFeedbackInfo feedbackData={feedbackData} />

        <FeedbackBox>
          <MText variant="subtitle">
            {_t(langKeys.log_feedback_event_reported_correctly)}
          </MText>

          <RadioGroup
            value={response}
            onChange={(e) => setResponse(e.target.value as TResponseType)}
          >
            {RESPONSES.map((choice) => (
              <FormControlLabel
                key={choice.value}
                value={choice.value}
                control={<Radio />}
                label={_t(choice.label)}
              />
            ))}
          </RadioGroup>

          <TextField
            fullWidth
            placeholder={_t(langKeys.smoking_detection_feedback_comment_hint)}
            minRows={3}
            multiline
            value={comment ?? ""}
            onChange={(e) => setComment(e.target.value)}
          />
        </FeedbackBox>
      </DialogContent>
    </MDialog>
  )
}

const DialogContent = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const FeedbackBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

export type TEventFeedbackData = {
  area: string
  address: string | undefined
  date: string
  timezone: Optional<IHome["timezone"]>
  eventId: IEvent["event_id"]
  deviceId: IEvent["device_id"]
}

function SmokeFeedbackInfo({
  feedbackData,
}: {
  feedbackData: TEventFeedbackData
}) {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()
  const fetchDeviceName = useFetchDevice({
    orgId,
    deviceId: feedbackData.deviceId ?? "",
    options: {
      enabled: !!feedbackData.deviceId,
      select(data) {
        return data.description
      },
    },
  })
  const deviceName = fetchDeviceName.data

  return (
    <SmokeFeedbackInfoBox>
      <MText variant="bodyS" color="secondary">
        {[
          deviceName,
          feedbackData.area,
          feedbackData.address,
          formatDate({
            date: feedbackData.date,
            timezone: feedbackData.timezone,
            clockType: user.clock_type,
          }),
        ]
          .filter(Boolean)
          .join(" · ")}
      </MText>

      <CigaretteIcon width={24} />
      <MText variant="subtitle">
        {t(langKeys.smoking_detection_alert_title)}
      </MText>

      <MText variant="bodyS" color="secondary">
        {t(langKeys.smoking_detection_smoking_detected_details)}
      </MText>
    </SmokeFeedbackInfoBox>
  )
}

const SmokeFeedbackInfoBox = styled.div`
  padding: ${spacing.M};
  background-color: #e9ecf2;
  border-radius: 8px;

  display: grid;
  grid-template-columns: ${spacing.XL} auto;

  & > *:not(svg) {
    grid-column: 2;
  }
`

const storedCigaretteFeedback = localStorageFactory({
  key: "minut.cigarette_feedback",
})

function useStoredCigaretteFeedback() {
  return useStorage({ storage: storedCigaretteFeedback, initialValue: "" })
}
