import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { TDateISO } from "src/data/devices/types/deviceTypes"
import { eventKeys } from "src/data/events/queries/eventQueryCache"
import { IEventQuery } from "src/data/events/types/eventTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchEvents({
  limit = 10,
  offset = 0,
  organizationId, // Will only return events after 2022-08-16.
  homeId,
  homeIds,
  options,
  startAt,
  endAt,
  eventIdFilter,
}: {
  limit?: number
  offset?: number
  organizationId: string
  homeId?: string
  homeIds?: string[]
  eventIdFilter?: string[]
  startAt?: TDateISO
  endAt?: TDateISO
  options?: UseQueryOptions<
    IEventQuery,
    AxiosError,
    IEventQuery,
    ReturnType<typeof eventKeys.list>
  >
}) {
  async function fetchEvents(limit: number, offset: number) {
    const response = await minutApiHttpClient.get<IEventQuery>(
      `${API_DEFAULT}/events`,
      {
        params: {
          order: "desc",
          limit: limit ?? 10,
          offset: offset ?? 0,
          organization_id: organizationId,
          home_id: homeId,
          home_ids: homeIds,
          events: eventIdFilter,
          start_at: startAt,
          end_at: endAt,
        },
      }
    )
    return response.data
  }

  return useQuery(
    eventKeys.list({
      orgId: organizationId,
      limit,
      offset,
      homeIds,
      events: eventIdFilter,
      startAt,
      endAt,
    }),
    () => fetchEvents(limit, offset),
    options
  )
}
