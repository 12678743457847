import { EventIcon } from "src/components/EventLog/EventIcon"
import {
  EVENT_KEY,
  EventType,
  IEvent,
  IEventTextParam,
} from "src/data/events/types/eventTypes"
import { TClockTypeMaybe, TTemperatureUnit } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { ErrorService } from "src/utils/ErrorService"
import { formatDate, formatTemperatureString } from "src/utils/l10n"
import { debug } from "src/utils/logger"

const eventTypeSet = new Set(Object.values(EventType))

function hasKey(key: string) {
  return Object.keys(langKeys).includes(key)
}

export interface IEventOptions {
  clockType?: TClockTypeMaybe
  temperatureUnit?: TTemperatureUnit
  timezone?: string
}

export function generateEventData(event: IEvent, options: IEventOptions) {
  const eventType = event.type

  if (!eventTypeSet.has(eventType)) {
    debug.log("Unhandled event:", eventType)
    ErrorService.captureMessage(`Unhandled event ${eventType}`)
  }
  const titleKey = `${eventType}_title`
  const bodyKey = `${eventType}_body`

  return {
    icon: EventIcon(eventType),
    titleKey:
      (hasKey(titleKey) && titleKey) ||
      (hasKey(bodyKey) && bodyKey) ||
      eventType,
    sortedTitleParams: createSortedTextParamsArray(
      event.title_text_params,
      options
    ),
    bodyKey: hasKey(bodyKey) && bodyKey,
    sortedBodyParams: createSortedTextParamsArray(event.text_params, options),
  }
}

/**
 * This nested array is used to handle the proper sorting of event details
 * (which may not always arrive in the correct order).
 */
const SORTING_RULES = [
  ["inviter_name", "home_name"],
  ["temperature_max", "temperature_min"],
  [EVENT_KEY.temperature, EVENT_KEY.humidity],
  ["sound_threshold", "duration_minutes", EVENT_KEY.next_notification_at],
  ["duration_minutes", EVENT_KEY.next_notification_at],
  [
    "sound_threshold",
    "duration_minutes",
    "current_sound_level",
    EVENT_KEY.next_notification_at,
  ],
]

/**
 * Take an array of text interpolation parameters, and sort them according to
 * the pre-defined rules in sortingRules if applicable.
 */
export function createSortedTextParamsArray(
  textParams?: IEventTextParam[],
  options?: IEventOptions
) {
  if (!textParams || !options) {
    return []
  }
  const unsortedArray = textParams
    ?.map((item) => formatItem(item, options))
    .filter((item) => item.key !== EVENT_KEY.device_name)

  if (!unsortedArray?.length) {
    return [] // no valid text params in array
  } else if (unsortedArray.length === 1) {
    return [unsortedArray[0].value] // no sorting needed, just return the first value immediately
  }

  const textParamKeys = unsortedArray.map((item) => item.key).sort()
  const applicableSortingRule = SORTING_RULES.find(
    (rule) =>
      JSON.stringify(rule.slice().sort()) === JSON.stringify(textParamKeys)
  )

  return (
    applicableSortingRule
      ? makeSorted(unsortedArray, applicableSortingRule)
      : unsortedArray
  ).map((item) => item?.value)
}

/** Transform value */
export function formatItem(
  item: IEventTextParam,
  {
    clockType,
    temperatureUnit,
    timezone,
  }: {
    clockType?: TClockTypeMaybe
    temperatureUnit?: TTemperatureUnit
    timezone?: string
  }
) {
  switch (item.key) {
    case EVENT_KEY.last_heard_from_at:
    case EVENT_KEY.next_notification_at:
      return {
        key: item.key,
        value: formatDate({ date: item.value, clockType, timezone }),
      }
    case EVENT_KEY.temperature:
      return {
        key: item.key,
        value: formatTemperature(item.value, temperatureUnit),
      }
    case EVENT_KEY.humidity:
      return {
        key: item.key,
        value: `${Number(item.value).toFixed(0)}%`,
      }
    case EVENT_KEY.response_service_automatically_dispatched_at:
      return {
        key: item.key,
        value: formatDate({ date: item.value, clockType, timezone }),
      }
    default:
      return item
  }
}

function formatTemperature(temp: string, unit: TTemperatureUnit) {
  const _unit = unit || (navigator.language === "en-US" ? "F" : "C")
  return formatTemperatureString(Number(temp), _unit, 0)
}

/**
 * Take an unordered list of text param objects and sort them according to the
 * keys in sortingArray
 */
function makeSorted(textParams: IEventTextParam[], sortingArray: string[]) {
  return sortingArray
    .map((key) => {
      return textParams.find((item) => item.key === key)
    })
    .filter(Boolean)
}
