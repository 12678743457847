import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState/EmptyState"
import ColoredSensorSvg from "src/ui/icons/marketing-system-icons/sensor-colored.svg"
import { spacing } from "src/ui/spacing"

import { AppStoreLogos } from "./AppStoreLogos"

export function NoEvents() {
  return (
    <EmptyState
      icon={<ColoredLargeSensorSvg />}
      title="You have not received any events yet"
      body={
        <Grid>
          Add a sensor from the Minut mobile app and events will show up here.
          <AppStoreLogos />
        </Grid>
      }
    />
  )
}

const ColoredLargeSensorSvg = styled(ColoredSensorSvg)`
  width: 150px;
  height: 150px;
`

const Grid = styled.div`
  display: grid;
  place-items: center;
  gap: ${spacing.M};
`
